<template>
  <div class="jsmb-account-information-detail">
  <h1>POLITIQUE DE PROTECTION DES DONN&Eacute;ES PERSONNELLES</h1>
  <h2><br />&Agrave; QUOI LA PR&Eacute;SENTE POLITIQUE DE PROTECTION DES DONNEES PERSONNELLES S&rsquo;APPLIQUE-T-ELLE ?</h2>
  <p>La pr&eacute;sente politique de protection des donn&eacute;es personnelles s&rsquo;applique &agrave; ce site internet, ainsi qu&rsquo;&agrave; toute autre initiative en ligne appartenant &agrave; la soci&eacute;t&eacute; JE STOCKE MON BATEAU et proc&eacute;dant &agrave; la collecte de donn&eacute;es personnelles (les &laquo; Supports &raquo;). Par cons&eacute;quent, la pr&eacute;sente Politique ne s&rsquo;applique pas aux sites internet de tiers, y compris &agrave; ceux &eacute;ventuellement mentionn&eacute;s sur nos Supports par l&rsquo;interm&eacute;diaire d&rsquo;un lien internet.<br />Veuillez noter que si la l&eacute;gislation de votre pays l&rsquo;exige, la pr&eacute;sente politique de protection des donn&eacute;es personnelles peut &ecirc;tre compl&eacute;t&eacute;e par des dispositions locales que nous vous invitons vivement &agrave; consulter sur nos Supports.</p>
  <h3>QUELLES DONNEES COLLECTONS-NOUS AUPR&Egrave;S DE VOUS ?</h3>
  <p>Avec votre consentement pr&eacute;alable, lorsque vous utilisez nos Supports nous sommes susceptibles de collecter et de traiter tout ou partie des donn&eacute;es suivantes :</p>
  <ul>
  <li>les donn&eacute;es que vous fournissez (i) en remplissant des formulaires, (ii) en chargeant en ligne des contenus , (iii) en souscrivant &agrave; des services en ligne (ex : applications et pages r&eacute;seaux sociaux) ou (iv) en nous adressant des correspondances :
  <ul>
  <li>pr&eacute;nom et nom de famille ;</li>
  <li>adresse de courrier &eacute;lectronique ;</li>
  <li>sexe ;</li>
  <li>num&eacute;ro de t&eacute;l&eacute;phone ;</li>
  <li>adresse postale ;</li>
  <li>&acirc;ge/date de naissance ;</li>
  <li>votre opinion sur nos produits ;</li>
  <li>informations de paiement (sur les Supports commerce &eacute;lectronique) ;</li>
  <li>votre historique d&rsquo;achats (sur les Supports commerce &eacute;lectronique) ;</li>
  <li>toute demande particuli&egrave;re que vous pourriez nous adresser (principalement pour des besoins d&rsquo;archivage) ;</li>
  <li>les photos ou vid&eacute;os de vous que vous souhaitez partager.</li>
  </ul>
  </li>
  <li>Nous sommes susceptibles de vous demander de r&eacute;pondre &agrave; des sondages que nous utilisons &agrave; des fins scientifiques et statistiques.</li>
  </ul>
  <h3>COLLECTONS-NOUS VOTRE ADRESSE IP ET VOS T&Eacute;MOINS DE CONNEXION (COOKIES)?</h3>
  <p>Nous sommes susceptibles de collecter votre adresse IP et votre type de navigateur &agrave; des fins d&rsquo;administration de nos syst&egrave;mes et/ou d&rsquo;&eacute;tablissement de statistiques et sans identifier une quelconque autre information individuelle.</p>
  <p>Dans certains cas, nous sommes &eacute;galement susceptibles d&rsquo;utiliser des cookies qui correspondent &agrave; des petits fichiers de lettres et de chiffres t&eacute;l&eacute;charg&eacute;s sur votre terminal lorsque vous acc&eacute;dez &agrave; nos Supports. Nous utilisons principalement les cookies pour vous reconna&icirc;tre lorsque vous vous connectez &agrave; nouveau &agrave; nos Supports et pour afficher un contenu susceptible de correspondre &agrave; vos centres d&rsquo;int&eacute;r&ecirc;ts.</p>
  <p>Les param&egrave;tres par d&eacute;faut des navigateurs internet sont habituellement r&eacute;gl&eacute;s de mani&egrave;re &agrave; accepter les cookies, mais vous pouvez facilement changer cela en modifiant les param&egrave;tres de votre navigateur. Toutefois, veuillez noter que si vous choisissez de d&eacute;sactiver les cookies, il se peut que certaines parties de nos Supports ne soient pas accessibles.<br />Pour toute question concernant les cookies : contactez nous &agrave; l&rsquo;adresse <a href="mailto:contact@jestockemonbateau.fr">contact@jestockemonbateau.fr</a></p>
  <h3>COMMENT UTILISONS-NOUS VOS DONN&Eacute;ES PERSONNELLES ?</h3>
  <p>Nous utilisons principalement vos donn&eacute;es personnelles dans le cadre de la gestion de nos clients / prospects, et notamment pour r&eacute;pondre &agrave; vos demandes ou pour vous envoyer r&eacute;guli&egrave;rement des nouvelles et des informations sur nos produits, nos marques, nos op&eacute;rations et/ou nos Supports susceptibles de susciter votre int&eacute;r&ecirc;t.<br />Nous utilisons &eacute;galement vos donn&eacute;es pour am&eacute;liorer votre exp&eacute;rience digitale sur nos Supports : comprendre l&rsquo;int&eacute;r&ecirc;t que vous portez &agrave; nos Supports et &agrave; leur contenu, g&eacute;rer votre compte en ligne, valider votre participation &agrave; nos op&eacute;rations, traiter vos paiements, faire en sorte que nos Supports soient pr&eacute;sent&eacute;s de la mani&egrave;re qui soit la plus adapt&eacute;e &agrave; vous et &agrave; votre terminal, et vous prot&eacute;ger contre d&rsquo;&eacute;ventuelles actions frauduleuses.</p>
  <h3>COMMENT POUVEZ-VOUS ACC&Eacute;DER &Agrave; VOS DONN&Eacute;ES PERSONNELLES ?</h3>
  <p>Vous avez toujours la possibilit&eacute; de nous contacter par courrier postal, par courrier &eacute;lectronique ou par t&eacute;l&eacute;phone (veuillez-vous reporter aux coordonn&eacute;es indiqu&eacute;es dans les conditions d&rsquo;utilisation des Supports que vous utilisez) pour consulter les donn&eacute;es personnelles vous concernant qui sont en notre possession. <br />Si vous identifiez une erreur parmi ces donn&eacute;es ou si vous les jugez incompl&egrave;tes ou ambig&uuml;es, vous pouvez &eacute;galement nous demander de les corriger, de les compl&eacute;ter ou de les clarifier. <br />Vous pouvez &eacute;galement vous opposer &agrave; ce que vos donn&eacute;es soient utilis&eacute;es &agrave; des fins de prospection commerciale ou communiqu&eacute;es &agrave; des tiers &agrave; cette fin. <br />Enfin, vous pouvez nous demander de supprimer toute donn&eacute;e personnelle qui vous concerne et que nous d&eacute;tenons. Toutefois, veuillez noter que nous sommes susceptibles de conserver une trace de vos donn&eacute;es &agrave; des fins d&rsquo;archivage (en particulier lorsque la loi nous l&rsquo;impose).</p>
  <h3>PARTAGEONS-NOUS VOS DONN&Eacute;ES PERSONNELLES ?</h3>
  <p>Sous r&eacute;serve de ce qui est indiqu&eacute; dans la pr&eacute;sente politique de protection des donn&eacute;es personnelles ou &agrave; moins que nous n&rsquo;ayons pr&eacute;alablement obtenu votre consentement, nous sommes susceptibles de communiquer vos donn&eacute;es personnelles avec des personnes employ&eacute;es de notre soci&eacute;t&eacute; ou nos prestataires de services (par exemple, nos agences Web qui g&egrave;rent pour notre compte certaines op&eacute;rations en ligne) et/ou partenaires commerciaux pour les finalit&eacute;s pr&eacute;c&eacute;demment mentionn&eacute;es, et uniquement dans la limite n&eacute;cessaire &agrave; l&rsquo;accomplissement des t&acirc;ches qui leurs sont confi&eacute;es.</p>
  <p>Ces personnes pourront &ecirc;tre amen&eacute;es &agrave; vous contacter directement &agrave; partir des coordonn&eacute;es que vous nous avez communiqu&eacute;es.<br />Veuillez noter que nous exigeons de mani&egrave;re stricte de nos prestataires de services qu&rsquo;ils utilisent vos donn&eacute;es personnelles uniquement pour g&eacute;rer les services que nous leur demandons de fournir. Nous demandons &eacute;galement &agrave; ces prestataires de toujours agir en conformit&eacute; avec les lois applicables en mati&egrave;re de protection de donn&eacute;es personnelles et d&rsquo;accorder une attention particuli&egrave;re &agrave; la confidentialit&eacute; de ces donn&eacute;es.</p>
  <h3>O&Ugrave; STOCKONS-NOUS VOS DONN&Eacute;ES PERSONNELLES ?</h3>
  <p>Vos donn&eacute;es personnelles sont stock&eacute;es soit dans nos bases de donn&eacute;es soit dans celles de nos prestataires de services.<br />Dans certains cas, et principalement pour des raisons techniques, ces bases de donn&eacute;es peuvent &ecirc;tre stock&eacute;es sur des serveurs situ&eacute;s en dehors du pays (y compris en dehors de l&rsquo;Union europ&eacute;enne) depuis lequel vous avez initialement saisi les donn&eacute;es.</p>
  <h3>VOS DONN&Eacute;ES PERSONNELLES SONT-ELLES CONSERV&Eacute;ES EN S&Eacute;CURIT&Eacute; ?</h3>
  <p>Nous avons pour objectif de toujours conserver vos donn&eacute;es personnelles de la mani&egrave;re la plus s&ucirc;re et la plus s&eacute;curis&eacute;e, et uniquement pendant la dur&eacute;e n&eacute;cessaire &agrave; la r&eacute;alisation de la finalit&eacute; poursuivie par le traitement. Dans cette perspective, nous prenons les mesures physiques, techniques et organisationnelles appropri&eacute;es pour emp&ecirc;cher dans toute la mesure du possible toute alt&eacute;ration ou perte de vos donn&eacute;es ou tout acc&egrave;s non autoris&eacute; &agrave; celles-ci. <br />Sur nos Supports de commerce &eacute;lectronique, nous g&eacute;rons vos informations de paiement &agrave; l&rsquo;aide de m&eacute;thodes r&eacute;pondant aux normes de l&rsquo;industrie (telles que PCI DSS).<br />Nous vous informons que cette politique de protection des donn&eacute;es personnelles peut &ecirc;tre modifi&eacute;e par nos soins. Dans ce cas, ces modifications seront consultables sur cette page.</p>
  <h3>DISPOSITIONS LOCALES</h3>
  <p>France<br />Conform&eacute;ment &agrave; la loi n&deg;78-17 du 6 janvier 1978 modifi&eacute;e relative &agrave; l&rsquo;informatique, aux fichiers et aux libert&eacute;s, vous disposez d&rsquo;un droit d&rsquo;acc&egrave;s, de modification, de rectification et de suppression des donn&eacute;es vous concernant. Pour exercer ce droit, vous devez nous adresser une demande accompagn&eacute; d&rsquo;une pi&egrave;ce d&rsquo;identit&eacute; &agrave; : contact@jestockemonbateau.fr.</p>   
  </div>
</template>

<script>
export default {
  name: 'Confidentiality',
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'Confidentiality' });

    let title = (siteName+' - Politique de confidentialité - Stockage de bateaux chez les particuliers et professionnels - Hivernage Bateau - Stockage bateau hors d\'eau'),
      description = siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels avec une assurance ! La première plateforme dans le monde mettant en relation des propriétaires d\'un jardin, d\'un abri, d\'un box ou d\'un garage, avec des plaisanciers qui recherchent un endroit où stocker leur bateau.';

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
          { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description,
          'vmid': 'og:description'
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'name': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'name': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
       {
          'name': 'twitter:image',
          'content': (baseUrl+require('./../../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  h1 {font-size: 1.5rem}
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 1rem;
  }
  ul{
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 1rem;
  }
  li {
    margin-bottom: 0.5rem;
  }
  input:not([type="radio"]){
    margin-bottom: 0.5rem;
    border:none;
    box-shadow: none;
  }
  textarea {
    box-shadow: none;
    border:none
  }
  input[type=radio] {
  border: 1px solid #df5051;
  padding: 0.5em;
  -webkit-appearance: none;
}
input[type=radio]:checked {
  background-size: 9px 9px;
  background-color: #df5051;
}
input[type=radio]:focus {
  outline-color: none;
}
.radio {
  font-size:0.85rem
}


</style>
